import * as React from 'react';
import { getText, cn, getConfig, localeDate } from '../../helpers';
import NotifyMe from '../notify-me';

import { ReactComponent as ClockIcon } from '../../assets/svg/icons/clock.svg';
import { ReactComponent as RefreshIcon } from '../../assets/svg/icons/refresh.svg';
import { ReactComponent as AddedCircleIcon } from '../../assets/svg/icons/added-circle.svg';

const ClockIconInstance = <ClockIcon />;
const RefreshIconInstance = <RefreshIcon />;
const AddedCircleIconInstance = <AddedCircleIcon />;

const flagOptions: FlagOptions = {
    'coming-soon': {
        text: getText('generic.comingSoon'),
        icon: ClockIconInstance,
        className: 'data-flag--coming-soon'
    },
    'coming-soon-profile': {
        text: getText('generic.comingSoon'),
        icon: ClockIconInstance,
        className: 'data-flag--coming-soon-inverted'
    },
    'recently-added': {
        text: getText('generic.recentlyAdded'),
        icon: AddedCircleIconInstance,
        className: 'data-flag--recently-added'
    },
    'recently-added-profile': {
        text: getText('generic.addedOn'),
        icon: AddedCircleIconInstance,
        className: 'data-flag--recently-added-inverted'
    },
    'recently-enhanced': {
        text: getText('generic.recentlyEnhanced'),
        icon: RefreshIconInstance,
        className: 'data-flag--alternative'
    },
    'recently-enhanced-profile': {
        text: getText('generic.enhancedOn'),
        icon: RefreshIconInstance,
        className: 'data-flag--alternative-inverted'
    },
    default: {
        text: '',
        icon: null,
        className: ''
    }
};

const formattedDate = (flagType: string, datasetAddedDate: string, datasetEnhancementDate?: string) => {
    if (flagType === 'recently-enhanced-profile') return ` ${localeDate(datasetEnhancementDate)}`;
    if (flagType === 'recently-added-profile') return ` ${localeDate(datasetAddedDate)}`;
    return '';
};

// datetime calculations are in miliseconds (90 days equal to 7776000000 Milliseconds)
const flagCutOff = getConfig('components.flagVisibilityCutoffTime');

function getFlagType(addedDate?: string, enhancedDate?: string): string {
    const dateTodayMinus90 = new Date().getTime() - flagCutOff;
    const dateToday = new Date().getTime();

    const dateDatasetAdded = addedDate && Date.parse(addedDate);
    const dateDatasetEnhanced = enhancedDate && Date.parse(enhancedDate);

    if (dateDatasetAdded && dateDatasetAdded > dateToday) {
        return 'coming-soon';
    }
    if (dateDatasetAdded && dateDatasetAdded > dateTodayMinus90 && dateDatasetAdded <= dateToday) {
        return 'recently-added';
    }
    if (dateDatasetEnhanced && (dateDatasetEnhanced > dateTodayMinus90 && dateDatasetEnhanced < dateToday)) {
        return 'recently-enhanced';
    }
    return 'default';
}

const getFlagVariant = (variant?: string) => {
    return variant ? `-${variant}` : '';
};

const DataFlag = function DataFlag(props: DataFlagObject) {
    const { datasetAddedDate, datasetEnhancementDate, variant, datasetId, datasetName, notifyVariant = 'dataset', isSmall = false } = props;
    const flag = getFlagType(datasetAddedDate, datasetEnhancementDate);
    const flagType: string = flag + getFlagVariant(variant);

    if (flagType.startsWith('default')) {
        return null;
    }

    const flagClasses = cn('data-flag', flagOptions[flagType].className, {
        'data-flag--small': isSmall
    });

    const date = formattedDate(flagType, datasetAddedDate, datasetEnhancementDate);

    return (
        <div className="data-flag__container">
            <span data-testid="data-flag" className={flagClasses}>
                {flagOptions[flagType].icon}
                {flagOptions[flagType].text + date}
            </span>
            {flagType === 'coming-soon-profile' ? <NotifyMe id={datasetId || 0} text={datasetName || ''} variant={notifyVariant} className="data-flag__notifyme" /> : null}
        </div>
    );
};

export default React.memo(DataFlag);
