import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import keyPages from './key-pages';
import appInsights from './application-insights';

const AsyncHome = React.lazy(() => import('../routes/homepage' /* webpackChunkName: "homepage" */));
const AsyncProductFinder = React.lazy(() => import('../routes/product-finder' /* webpackChunkName: "product-finder" */));
const AsyncSearchResults = React.lazy(() => import('../routes/search-results' /* webpackChunkName: "search-results" */));
const AsyncDatasets = React.lazy(() => import('../routes/datasets' /* webpackChunkName: "datasets" */));
const AsyncDataset = React.lazy(() => import('../routes/dataset' /* webpackChunkName: "dataset" */));
const AsyncSolutions = React.lazy(() => import('../routes/solutions' /* webpackChunkName: "solutions" */));
const AsyncSolution = React.lazy(() => import('../routes/solution' /* webpackChunkName: "solution" */));
const AsyncProfilePage = React.lazy(() => import('../routes/profile-page' /* webpackChunkName: "profile-page" */));
const AsyncDataVendor = React.lazy(() => import('../routes/data-partners' /* webpackChunkName: "data-partners" */));
const AsyncRequestTrial = React.lazy(() => import('../routes/request-trial-page' /* webpackChunkName: "request-a-trial" */));
const AsyncFAQ = React.lazy(() => import('../routes/faq-page' /* webpackChunkName: "faq" */));
const AsyncQueryLibrary = React.lazy(() => import('../routes/query-library-page' /* webpackChunkName: "query-library-page" */));
const AsyncQueryBuilder = React.lazy(() => import('../routes/query-builder' /* webpackChunkName: "query-builder" */));
const AsyncBuildQuery = React.lazy(() => import('../routes/build-query' /* webpackChunkName: "query-builder-new-query" */));
const AsyncQueryBuilderDownload = React.lazy(() => import('../routes/query-builder-download' /* webpackChunkName: "query-builder-download" */));
const AsyncBlueprint = React.lazy(() => import('../routes/blueprint' /* webpackChunkName: "blueprint" */));
const AsyncBlueprints = React.lazy(() => import('../routes/blueprints' /* webpackChunkName: "blueprints" */));

const isDev = process.env.NODE_ENV === 'development';

const SetAIOperationId = () => {
    if (appInsights) appInsights.context.telemetryTrace.traceID = uuidv4();
};

const AsyncFakeLogin = isDev ? React.lazy(() => import('../routes/fake-login' /* webpackChunkName: "fake-login" */)) : null;

const HomePage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncHome {...props} keyPage={keyPages.homePage} startDate={new Date()} />;
};

const SearchResultsPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncSearchResults {...props} keyPage={keyPages.searchResultsPage} startDate={new Date()} />;
};

const DatasetsPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncDatasets {...props} keyPage={keyPages.datasetsPage} startDate={new Date()} />;
};

const ProductFinderPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncProductFinder {...props} keyPage={keyPages.productFinderPage} startDate={new Date()} />;
};

const DatasetPage = (props: PageProps & DatasetProps) => {
    SetAIOperationId();
    return <AsyncDataset {...props} keyPage={keyPages.datasetPage} startDate={new Date()} />;
};

const DatasetBundlePage = (props: PageProps & DatasetProps) => {
    SetAIOperationId();
    return <AsyncDataset {...props} keyPage={keyPages.datasetPage} startDate={new Date()} />;
};

const SolutionsPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncSolutions {...props} keyPage={keyPages.solutionsPage} startDate={new Date()} />;
};

const SolutionPage = (props: PageProps & SolutionProps) => {
    SetAIOperationId();
    return <AsyncSolution {...props} keyPage={keyPages.solutionPage} startDate={new Date()} />;
};

const ProfilePage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncProfilePage {...props} keyPage={keyPages.profilePage} startDate={new Date()} />;
};

const NewUserSignUpPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncRequestTrial {...props} keyPage={keyPages.datasetRATForm} startDate={new Date()} />;
};

const DataVendorPage = (props: PageProps & DataVendorState) => {
    SetAIOperationId();
    return <AsyncDataVendor {...props} keyPage={keyPages.vendorForm} startDate={new Date()} />;
};

const FAQPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncFAQ {...props} keyPage={keyPages.faq} startDate={new Date()} />;
};

const QueryLibraryPage = (props: QueryLibraryPageProps) => {
    SetAIOperationId();
    return <AsyncQueryLibrary {...props} keyPage={keyPages.queryLibraryPage} startDate={new Date()} />;
};

const QueryBuilderPage = (props: QueryBuilderPageProps) => {
    SetAIOperationId();
    return <AsyncQueryBuilder {...props} keyPage={keyPages.queryBuilderPage} startDate={new Date()} />;
};
const BuildQueryPage = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncBuildQuery {...props} keyPage={keyPages.queryBuilderNewQuery} startDate={new Date()} />;
};
const QueryBuilderDownload = (props: PageProps) => {
    SetAIOperationId();
    return <AsyncQueryBuilderDownload {...props} keyPage={keyPages.queryBuilderDownload} startDate={new Date()} />;
};

const BlueprintPage = (props: PageProps & BlueprintProps) => {
    SetAIOperationId();
    return <AsyncBlueprint {...props} keyPage={keyPages.blueprintPage} startDate={new Date()} />;
};

const BlueprintsPage = (props: PageProps & BlueprintsProps) => {
    SetAIOperationId();
    return <AsyncBlueprints {...props} keyPage={keyPages.blueprintPage} startDate={new Date()} />;
};


export {
    HomePage,
    ProductFinderPage,
    SearchResultsPage,
    DatasetsPage,
    DatasetPage,
    DatasetBundlePage,
    SolutionsPage,
    SolutionPage,
    NewUserSignUpPage,
    ProfilePage,
    DataVendorPage,
    FAQPage,
    QueryLibraryPage,
    QueryBuilderPage,
    BuildQueryPage,
    QueryBuilderDownload,
    BlueprintPage,
    BlueprintsPage,
    AsyncFakeLogin
};
