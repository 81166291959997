import Cryptr from 'cryptr';
import SPGVariables from '../utilities/spg-variables';
import { getText } from '../helpers';

const spgVariables = SPGVariables();

const secret = spgVariables.REFRESHTOKEN || 'TopSecretKey';

const cryptr = new Cryptr(secret.substr(0, 12));

const langCode = getText('langCode');

const loadState = () => {
    try {
        let serializedState = null;
        let serializedSessionUserState = null;
        if (process.env.NODE_ENV !== 'production') {
            serializedState = localStorage.getItem('state');
            serializedSessionUserState = localStorage.getItem('stateUser');
        }

        if (serializedState === null) return undefined;

        const stateObject: RootStoreState = JSON.parse(serializedState);

        if (stateObject.version === `${spgVariables.BUILD_VERSION}-${langCode}`) {
            if (serializedSessionUserState !== null) {
                try {
                    const sessionStateObject: RootStoreState = JSON.parse(cryptr.decrypt(serializedSessionUserState));
                    // const sessionStateObject: RootStoreState = JSON.parse(serializedSessionUserState);
                    Object.assign(stateObject, { user: sessionStateObject });
                } catch {
                    sessionStorage.removeItem('state');
                }
            }
            return stateObject;
        }
        if (stateObject.version.split('-')[0] === spgVariables.BUILD_VERSION && stateObject.version.split('-')[1] !== langCode) {
            localStorage.removeItem('state');
            return undefined;
        }

        localStorage.removeItem('state');
        sessionStorage.removeItem('state');
        return undefined;

    } catch {
        localStorage.removeItem('state');
        sessionStorage.removeItem('state');
        return undefined;
    }
};

const saveState = (state: RootStoreState) => {
    try {
        const {
            user,
            errors, // eslint-disable-line @typescript-eslint/no-unused-vars
            ...rest
        } = state;

        const serializedState = JSON.stringify(rest);
        const serializedSessionState = cryptr.encrypt(JSON.stringify(user));
        // const serializedSessionState = JSON.stringify(user);
        localStorage.setItem('state', serializedState);
        localStorage.setItem('stateUser', serializedSessionState);
    } catch {
        // pass
    }
};

export { loadState, saveState };
