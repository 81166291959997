import { createStore, combineReducers, applyMiddleware, compose, StoreEnhancer, AnyAction } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import update from 'react-addons-update';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { CLEAR_STORE } from './actionTypes';
import { getText } from '../helpers';
import SPGVariables from '../utilities/spg-variables';

import { reducer as userReducer, initialUserState } from './user/user.reducer';
import { reducer as datasetsReducer, initialDataSetState } from './datasets/datasets.reducer';
import { reducer as solutionsReducer, initialSolutionsState } from './solutions/solutions.reducer';
import { reducer as blueprintsReducer, initialBlueprintsState } from './blueprints/blueprints.reducer';
import { reducer as filterReducer, initialFiltersState } from './filters/filters.reducer';
import { reducer as errorsReducer, initialErrorState } from './errors/errors.reducer';
import { reducer as sharedReducer, initialSharedState } from './shared/shared.reducer';
import { reducer as queryBuilderReducer, initialQueryBuilderState } from './querybuilder/query-builder.reducer';

const spgVariables = SPGVariables();

const initialState = {
    version: `${spgVariables.BUILD_VERSION}`,
    user: initialUserState,
    datasets: initialDataSetState,
    solutions: initialSolutionsState,
    blueprints: initialBlueprintsState,
    filters: initialFiltersState,
    errors: initialErrorState,
    shared: initialSharedState,
    querybuilder: initialQueryBuilderState
};

const reducers = combineReducers<RootStoreState>({
    version: reducerWithInitialState(`${spgVariables.BUILD_VERSION}`),
    user: userReducer,
    datasets: datasetsReducer,
    solutions: solutionsReducer,
    blueprints: blueprintsReducer,
    filters: filterReducer,
    errors: errorsReducer,
    shared: sharedReducer,
    querybuilder: queryBuilderReducer
});

const storeReducer = (state: RootStoreState = initialState, action: AnyAction) => {
    if (action.type === CLEAR_STORE) {
        return update(state, { $set: initialState });
    }
    return reducers(state, action);
};

const thunk: ThunkMiddleware<RootStoreState, AnyAction> = thunkMiddleware;
const middlewareEnhancer = applyMiddleware(thunk);

const composedEnhancers: StoreEnhancer = compose(
    middlewareEnhancer,
    devToolsEnhancer({})
);

// TODO remove this any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enstore = createStore<RootStoreState, AnyAction, any, any>(storeReducer, composedEnhancers);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jastore = createStore<RootStoreState, AnyAction, any, any>(storeReducer, composedEnhancers);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const zhstore = createStore<RootStoreState, AnyAction, any, any>(storeReducer, composedEnhancers);

const store = () => {
    switch (getText('langCode')) {
        case 'ja':
            return jastore;
        case 'zh':
            return zhstore;
        default:
            return enstore;
    }
};

export { store as default, initialState };
