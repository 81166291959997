import * as React from 'react';
import { useClickAway } from 'react-use';
import { cn } from '../../../helpers';

import './dropdown-button.style.scss';
import HistoryInstance from '../../../utilities/history';

function Dropdownbutton(props: DropdownButtonProps) {
    const { className, listContent, buttonContent, buttonClass, openDropDownOnHover, isMobile, menuGrp } = props;
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const [contextMenuOpened, setContextMenuOpened] = React.useState<boolean>(false);
    const parentNode: React.RefObject<HTMLElement> = React.useRef(null);
    useClickAway(parentNode, () => setMenuOpen(false));

    React.useEffect(() => {
        const historyListener = HistoryInstance.listen(() => {
            setMenuOpen(false);
        });
        return () => {
            historyListener();
        };
    }, []);

    const backicon = '<';

    const showBorderBottomUnderItemOnMobile = (i: any) => {
        return i && i.props.children && i.props.children[0] && i.props.children[0].props && !i.props.children[0].props.isDisabled;
    };

    const onMouseLeaveHandler = () => {
        if (contextMenuOpened) {
            setTimeout(() => setContextMenuOpened(false), 100);
            return;
        }
        if (!isMobile && openDropDownOnHover) {
            setMenuOpen(false);
        }
    };

    const onContextMenuHandler = () => {
        const isFirefox = typeof navigator !== 'undefined' && /Firefox/i.test(navigator.userAgent);
        if (isFirefox) setContextMenuOpened(true);
    };

    return (
        <nav
            onMouseLeave={onMouseLeaveHandler}
            onContextMenu={onContextMenuHandler}
            onMouseEnter={() => !isMobile && openDropDownOnHover && setMenuOpen(true)}
            ref={parentNode}
            className={cn('dropdown-button', className)}
        >
            <button type="button" className={buttonClass} onClick={() => setMenuOpen(!menuOpen)} data-testid="dropdown-button">
                {buttonContent}
            </button>
            { isMobile ? (
                <ul data-testid="dropdown-button-list" className={cn('dropdown-button__listmobile', className, { 'dropdown-button__listmobile--active': menuOpen })}>
                    <li>
                        <button type="button" className="dropdown-button__backmenu" onClick={() => setMenuOpen(false)}>
                            <span className="dropdown-button__backicon">{backicon}</span>
                            <span className="dropdown-button__backtext">{menuGrp}</span>
                        </button>
                    </li>
                    {React.Children.map(listContent, (child: React.ReactElement, index: number) => (
                        <li className={cn('dropdown-button__itemmobile', { 'border-bottom': showBorderBottomUnderItemOnMobile(listContent[index + 1]) })}>{child}</li>
                    ))}
                </ul>
            ) : (
                <ul data-testid="dropdown-button-list" className={cn('dropdown-button__list', className, { 'dropdown-button__list--active': menuOpen })}>
                    {React.Children.map(listContent, (child: React.ReactElement, index: number) => (
                        <li className={cn('dropdown-button__item', { 'border-bottom': !child.props.isDisabled && (listContent[index + 1] && !listContent[index + 1].props.isDisabled) })}>{child}</li>
                    ))}
                </ul>
            )}

        </nav>
    );
}

export default React.memo(Dropdownbutton);
