import * as React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Store from '../redux/store';
import { SET_FLAGS } from '../redux/actionTypes';
import {
    HomePage,
    ProductFinderPage,
    SearchResultsPage,
    DatasetsPage,
    DatasetPage,
    DatasetBundlePage,
    SolutionsPage,
    SolutionPage,
    ProfilePage,
    NewUserSignUpPage,
    DataVendorPage,
    FAQPage,
    QueryLibraryPage,
    QueryBuilderPage,
    BuildQueryPage,
    QueryBuilderDownload,
    BlueprintPage,
    BlueprintsPage
} from '../utilities/router-manager';
import security from '../utilities/security';
import SPGVariables from '../utilities/spg-variables';
import history from '../utilities/history';
import GlobalHeader from '../components/global-header';
import BlackhatFooter from '../components/blackhat-header-footer/footer';
import BlackhatHeader from '../components/blackhat-header-footer/header';
import ErrorBoundary from '../components/_common/error-boundary';
import OktaLoginCallback from '../components/okta-login-callback';
import ProtectedRoute from '../utilities/protected-route';
import AnnonymousRoute from '../utilities/annonymous-route';
import Loader from '../components/_common/loader';
import ErrorMessage from '../components/error-message';
import { getText, get } from '../helpers';
import APIManager from '../utilities/api-manager';

const spgVariables = SPGVariables();
const isDev = process.env.NODE_ENV === 'development';
const AsyncFakeLogin = isDev ? React.lazy(() => import('./fake-login' /* webpackChunkName: "fake-login" */)) : null;
const langCode = getText('langCode');
const oktaAuth = new OktaAuth({
    pkce: true,
    issuer: spgVariables.OKTA_ISSUER_URL,
    clientId: spgVariables.OKTA_CLIENT_ID,
    // TODO Standardize to /okta/auth/callback
    redirectUri: `${window.location.origin}/${langCode}/solutions/demos/auth/callback`,
    scopes: ['openid', 'kensho:app:link-model-server', 'kensho:app:scribe', 'kensho:app:nerd']
});

const Routes = (props: FeatureFlags) => {
    const [reqHeader, setReqHeader] = React.useState<string>('');
    const redirectUser = React.useCallback((authService: OktaAuth) => {
        authService.signInWithRedirect({
            // Skip Okta login page by explicitly specifying the IDP to use for login
            idp: spgVariables.OKTA_IDP_ID
        });
    }, []);
    const [isAuthenticationProcessCompleted, setIsAuthenticationProcessCompleted] = React.useState<boolean>(false);
    const [showPages, setShowPages] = React.useState<boolean>(false);
    const isLoggedIn = useSelector((st: RootStoreState) => st.user.isLoggedIn);
    const userInfo = useSelector((st: RootStoreState) => st.user.userInfo);
    const enabledBlueprintFlag = useSelector((state: RootStoreState) => get(state, 'shared.flags.enabledBlueprintFlag', false));
    React.useEffect(() => {
        const userOriginCountryCodeFromSession = sessionStorage.getItem('userOriginCountryCode');
        if (userOriginCountryCodeFromSession === null) {
            APIManager.getReqHeaderByName('PMUSER_COUNTRY_CODE').then(response => {
                setReqHeader(response.headers.userorigincountrycode || '');
                sessionStorage.setItem('userOriginCountryCode', response.headers.userorigincountrycode);
            });
        } else {
            setReqHeader(userOriginCountryCodeFromSession || '');
        }
        const checkAuthentication = async () => {
            await oktaAuth.isAuthenticated();
            setIsAuthenticationProcessCompleted(true);
        };
        checkAuthentication();
    }, []);

    React.useEffect(() => {
        if (isAuthenticationProcessCompleted && (!isLoggedIn || (isLoggedIn && userInfo))) {
            setShowPages(true);
        }
    }, [isAuthenticationProcessCompleted, isLoggedIn, userInfo]);

    const { fflagEnableQueryBuilder, hideFooterFlag } = props;

    const handlePageLeave = () => {
        if (!history.location.pathname.includes('/search-results')) {
            Store.dispatch({ flags: { hideFooterFlag: false }, type: SET_FLAGS });
        }
    };

    React.useEffect(() => {
        const unlisten = history.listen(() => {
            handlePageLeave();
        });

        return () => {
            unlisten();
        };
    }, [history]);

    return (
        <Router history={history}>
            <Security oktaAuth={oktaAuth} onAuthRequired={redirectUser}>
                <BlackhatHeader />
                <GlobalHeader {...props} />
                <main role="main">
                    <ErrorBoundary replacementComponent={<ErrorMessage />}>
                        <React.Suspense fallback={<Loader minHeight="80vh" />}>
                            {showPages && (
                                <Switch>
                                    <Route path={['/', '/:locale', '/refresh', '/:locale/refresh']} exact component={HomePage} />
                                    <Route path="/:locale/support/product-finder" exact component={ProductFinderPage} />
                                    <Route path="/:locale/search-results" exact component={SearchResultsPage} />
                                    <Route path="/:locale/datasets" exact component={DatasetsPage} />
                                    <Route path="/:locale/datasets/:id" exact component={DatasetPage} />
                                    <Route path="/:locale/datasets/bundle/:id" exact component={DatasetBundlePage} />
                                    <Route path="/:locale/solutions" exact component={SolutionsPage} />
                                    <Route path="/:locale/:type/demos/auth/callback" component={OktaLoginCallback} />
                                    <Route path="/:locale/solutions/:id" exact component={SolutionPage} />
                                    { enabledBlueprintFlag && (<Route path="/:locale/blueprints" exact component={BlueprintsPage} />)}
                                    { enabledBlueprintFlag && (<Route path="/:locale/blueprints/:id" exact component={BlueprintPage} />)}
                                    <ProtectedRoute path="/:locale/my-account" exact component={ProfilePage} />
                                    <AnnonymousRoute path="/:locale/new-user-signup" exact component={NewUserSignUpPage} />
                                    <Route path="/:locale/support/data-partners" exact component={DataVendorPage} />
                                    <Redirect from="/:locale/data-vendor" to="/:locale/support/data-partners" />
                                    <Route path="/:locale/support/faq" exact component={FAQPage} />
                                    <Route path="/:locale/support/query-library" exact component={QueryLibraryPage} />
                                    {(spgVariables.SPGVARS_QUERY_BUILDER || fflagEnableQueryBuilder) && <ProtectedRoute path="/:locale/extract/new-query/:id" exact component={BuildQueryPage} />}
                                    {(spgVariables.SPGVARS_QUERY_BUILDER || fflagEnableQueryBuilder) && <Route path="/:locale/extract" exact component={QueryBuilderPage} />}
                                    {(spgVariables.SPGVARS_QUERY_BUILDER || fflagEnableQueryBuilder) && <ProtectedRoute path="/:locale/extract/download" exact component={QueryBuilderDownload} />}
                                    {AsyncFakeLogin && <Route path="/:locale/fake-login" exact component={AsyncFakeLogin} />}
                                    <Route>
                                        <ErrorMessage />
                                    </Route>
                                </Switch>
                            )}
                        </React.Suspense>
                    </ErrorBoundary>
                </main>
                {!hideFooterFlag && <BlackhatFooter />}
            </Security>
        </Router>
    );
};


const mapStateToProps = (state: RootStoreState): FeatureFlags => {
    return {
        fflagEnableQueryBuilder: get(state, 'shared.flags.fflagEnableQueryBuilder'),
        hideFooterFlag: get(state, 'shared.flags.hideFooterFlag')
    };
};

export default connect(mapStateToProps)(React.memo(Routes));
