import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as CaretRightIcon } from '../../../assets/svg/icons/caret-right.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/svg/icons/external-link.svg';
import { ReactComponent as SpImage } from '../../../assets/svg/product-finder-button-img.svg';
import { cn, getText, sanitizer } from '../../../helpers';
import { ReactComponent as DsImage } from '../../../assets/svg/icons/datasets-filter.svg';
import { ReactComponent as ProductFinderImage } from '../../../assets/svg/icons/product-finder.svg';
import { ReactComponent as QueryLibraryImage } from '../../../assets/svg/icons/query-library.svg';

function imgOnButton() {
    return (
        <SpImage className="hero-product-finder__buttonImg" />

    );
}

function filtersButtonIcon(filterName: string) {
    switch (filterName) {
        case 'Product Finder':
            return <ProductFinderImage style={{ height: '30px', width: '30px' }} />;
        case 'Query Library':
            return <QueryLibraryImage />;
        case 'Marketplace – Support Center':
            return <DsImage />;
        case 'Commodity Insights – Support Center':
            return <DsImage />;
        case 'Data Partners':
            return <DsImage />;
        case 'FAQ':
            return <DsImage />;
        default:
            return <DsImage />;
    }
}

function renderExternalLink(
    linkTextClass: string,
    linkClass: string,
    target: string,
    url: string,
    text: string,
    title = '',
    hasIcon: boolean | undefined,
    hasSVGIcon: boolean | undefined,
    renderTitle: boolean | undefined,
    focusRef?: (element: HTMLAnchorElement) => void,
    onClick?: (event: React.SyntheticEvent) => void,
    id = 'external-link'
) {
    const linkInner = (
        <>
            {hasSVGIcon ? filtersButtonIcon(text) : null}
            <span className={linkTextClass}>{text}</span>
            {hasIcon && <ExternalLinkIcon className="links__icon links__icon--external" />}
        </>
    );

    return (
        <a href={url} target={target} title={title} ref={focusRef} rel="noopener noreferrer" className={linkClass} onClick={onClick} data-testid={id}>
            {title && renderTitle && (
                <>
                    <span className="button__title">{title}</span>
                    <span>{linkInner}</span>
                </>
            )}
            {!renderTitle && linkInner}
        </a>
    );
}

function renderInternalLink(
    linkTextClass: string,
    linkClass: string,
    text: string,
    title = '',
    hasIcon: boolean | undefined,
    url: string,
    activeClassName?: string,
    focusRef?: (element: HTMLAnchorElement) => void,
    onClick?: (event: React.SyntheticEvent) => void,
    id = 'internal-link',
    isDisabled?: boolean | true,
    hasImage?: boolean | true,
    htmlText?: boolean | true,
    hasSVGIcon?: boolean | true
) {
    const langCode = getText('langCode');
    if (activeClassName !== undefined) {
        return (
            isDisabled ?
                <span className={linkTextClass}>{text}</span> :
                <NavLink to={`/${langCode}${url}`} title={title} innerRef={focusRef} className={linkClass} activeClassName={activeClassName} onClick={onClick}>
                    {hasImage ? imgOnButton() : null}
                    {hasSVGIcon ? filtersButtonIcon(text) : null}
                    {htmlText ?
                        <div dangerouslySetInnerHTML={{ __html: sanitizer('Let us help you<br />', {}) }} /> :
                        <span className={linkTextClass}>{text}</span>}
                    {hasIcon && <CaretRightIcon className="links__icon links__icon--internal" />}
                </NavLink>
        );
    }
    return (
        isDisabled ?
            <span className={linkTextClass}>sample</span> :
            <Link to={`/${langCode}${url}`} title={title} innerRef={focusRef} className={linkClass} onClick={onClick} data-testid={id}>
                {hasImage ? imgOnButton() : null}
                {hasSVGIcon ? filtersButtonIcon(text) : null}
                {htmlText ?
                    <div className={linkTextClass} dangerouslySetInnerHTML={{ __html: sanitizer(text, {}) }} /> :
                    <span className={linkTextClass}>{text}</span>}
                {hasIcon && <CaretRightIcon className="links__icon links__icon--internal" />}
            </Link>
    );
}

function CustomLink({
    target = '_blank',
    url,
    text,
    title = '',
    hasIcon,
    isDisabled,
    linkTheme,
    buttonTheme = 'secondary',
    isButton,
    isUpperCase,
    hasUnderline,
    noWrap,
    isFullWidth,
    className,
    activeClassName,
    onClick,
    focusRef,
    renderTitle,
    id = 'link',
    hasImage,
    htmlText,
    hasSVGIcon,
    isMobile
}: CustomLinkProps) {
    const isExternalLink = url ? url.match(/^(ftp|http|https):\/\/[^ "]+$/g) : '';
    const isEmailLink = url ? url.match(/^(mailto):/g) : '';

    const linkClassTheme = cn('links', className, {
        [`links--${linkTheme}`]: linkTheme,
        'links--uppercase': isUpperCase,
        'links--underline': hasUnderline,
        'links--icon': hasIcon,
        'links--fullwidth': isFullWidth,
        'links--nowrap': noWrap
    });

    const buttonClassTheme = cn('button', className, {
        [`button--${buttonTheme}`]: buttonTheme,
        'button--uppercase': isUpperCase,
        'button--icon': hasIcon,
        'button--fullwidth': isFullWidth,
        'button--nowrap': noWrap,
        'button--new-button-style': hasImage
    });

    const linkTextClass = cn('links__text', {
        'links__text--nowrap': noWrap,
        'links--disabled': isDisabled,
        'links--newlink': hasImage,
        'links--boldtext': isMobile
    });

    const handleClick = (event: React.SyntheticEvent) => {
        if (onClick) onClick(event);
    };
    const linkClass = isButton ? buttonClassTheme : linkClassTheme;

    if (isExternalLink || isEmailLink) {
        return renderExternalLink(linkTextClass, linkClass, target, url, text, title, hasIcon, hasSVGIcon, renderTitle, focusRef, handleClick, id);
    }
    return renderInternalLink(linkTextClass, linkClass, text, title, hasIcon, url, activeClassName, focusRef, handleClick, id, isDisabled, hasImage, htmlText, hasSVGIcon);
}

export default React.memo(CustomLink);
