import { createStore, combineReducers, applyMiddleware, compose, StoreEnhancer, AnyAction } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import update from 'react-addons-update';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { throttle, getConfig, getText } from '../helpers';
import { loadState, saveState } from './web-storage';
import { CLEAR_STORE } from './actionTypes';
import SPGVariables from '../utilities/spg-variables';

import { reducer as userReducer, initialUserState } from './user/user.reducer';
import { reducer as datasetsReducer, initialDataSetState } from './datasets/datasets.reducer';
import { reducer as solutionsReducer, initialSolutionsState } from './solutions/solutions.reducer';
import { reducer as blueprintsReducer, initialBlueprintsState } from './blueprints/blueprints.reducer';
import { reducer as filterReducer, initialFiltersState } from './filters/filters.reducer';
import { reducer as errorsReducer, initialErrorState } from './errors/errors.reducer';
import { reducer as sharedReducer, initialSharedState } from './shared/shared.reducer';
import { reducer as queryBuilderReducer, initialQueryBuilderState } from './querybuilder/query-builder.reducer';

const spgVariables = SPGVariables();
const langCode = getText('langCode');

export declare const redux: RootStoreState;

const initialState = {
    version: `${spgVariables.BUILD_VERSION}-${langCode}`,
    user: initialUserState,
    datasets: initialDataSetState,
    solutions: initialSolutionsState,
    blueprints: initialBlueprintsState,
    filters: initialFiltersState,
    errors: initialErrorState,
    shared: initialSharedState,
    querybuilder: initialQueryBuilderState
};

const reducers = combineReducers<RootStoreState>({
    version: reducerWithInitialState(`${spgVariables.BUILD_VERSION}-${langCode}`),
    user: userReducer,
    datasets: datasetsReducer,
    solutions: solutionsReducer,
    blueprints: blueprintsReducer,
    filters: filterReducer,
    errors: errorsReducer,
    shared: sharedReducer,
    querybuilder: queryBuilderReducer
});

const storeReducer = (state: RootStoreState = initialState, action: AnyAction) => {
    if (action.type === CLEAR_STORE) {
        return update(state, { $set: initialState });
    }
    return reducers(state, action);
};

const thunk: ThunkMiddleware<RootStoreState, AnyAction> = thunkMiddleware;
const middlewareEnhancer = applyMiddleware(thunk);

const composedEnhancers: StoreEnhancer = compose(
    middlewareEnhancer,
    devToolsEnhancer({})
);

let persistedState: {} | undefined;

if (!spgVariables.IS_SERVER_REQUEST) {
    persistedState = process.env.REACT_APP_IGNORE_LOCALSTORAGE !== 'true' && !spgVariables.IS_CRAWLER_REQUEST ? loadState() : undefined;
}

if (!spgVariables.IS_SERVER_REQUEST && redux) {
    if (!(persistedState === undefined)) {
        persistedState = update(persistedState, { $merge: redux }) as RootStoreState;
    } else {
        persistedState = redux;
    }
}
// TODO remove this any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const store = createStore<RootStoreState, AnyAction, any, any>(storeReducer, persistedState, composedEnhancers);

if (!spgVariables.IS_CRAWLER_REQUEST && !spgVariables.IS_SERVER_REQUEST) {
    store.subscribe(throttle(() => saveState(store.getState()), getConfig('redux.saveTimeout')));
}

export { store as default, initialState };
